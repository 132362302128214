import { axiosBackEnd, axiosImg, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const getThemeList = async (limit, offset, checked, order) => {
  const token = getCookie(TOKEN)
  return await axiosBackEnd.get(
    `/themes/viewer?currentPage=${offset}&pageSize=${limit}&checked=${checked}&order=${order}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null
  )
}

export const getThemeDetail = async themeId => {
  const token = getCookie(TOKEN)
  return await axiosBackEnd.get(
    `/themes/viewer/${themeId}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null
  )
}

export const getThemeDetailAsDesigner = async themeId => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(
    `/themes/designer/${themeId}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null
  )
}

export const getThemeListAsUser = async () => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(
    `/themes/user`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null
  )
}

export const themeSubscribe = async themeId => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.post(`/themes/subscriber/${themeId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const themeCancelSubscribe = async userThemeId => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.post(
    `/themes/subscriber/${userThemeId}/cancel`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const themeSubmit = async values => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.post(
    `/themes/designer`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  )
}

export const buyTheme = async themeId => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.post(`/themes/buyer/${themeId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function coverUpload(blob, filename, callback) {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  let formdata = new FormData()
  formdata.append("file", blob, filename)
  axiosImg
    .post("/file/upload", formdata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      callback(res.data)
    })
    .catch(err => {
      console.error(err)
    })
}
