import React from "react"
import { Pagination, Button, message } from "antd"

import Layout from "../../layouts"
import ThemeCard from "../../components/ThemeCard"

import "./index.css"
import { getJobList } from "../../api/job"

const template = new Array(7).fill({}).map((_, index) => ({
  id: index,
  title: "",
  url: "",
  coverUrl: "",
  qrCode: "",
}))

class JobList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jobList: template,
      total: 0,
      loading: true,
    }
  }

  componentWillMount() {
    this.initJobList()
  }

  initJobList = async () => {
    const res = await getJobList(2, 7)
    const data = res.data.data
    if (data) {
      const { list } = data
      this.setState({
        jobList: list,
        loading: false,
      })
    } else {
      message.error("加载工作界面出错")
    }
  }

  render() {
    const { jobList } = this.state
    return (
      <Layout isSpacerDisplay={true} title="Hello 部落">
        <div className="mdnice-theme-container">
          <div style={{display:"flex", justifyContent: "center"}}>
          <p style={{fontSize: "20px"}}>当前搜索结果为：</p>

          </div>

          <div className="mdnice-theme-list">
            {jobList.map((item, index) => (
              <ThemeCard key={index} {...item} />
            ))}
            <div style={{ width: 330, margin: "0em 1em" }} />
            <div style={{ width: 330, margin: "0em 1em" }} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default JobList
