import React from "react"
import { navigate } from "gatsby"
import { Button, Skeleton, message, Tag, Avatar } from "antd"

import { themeSubscribe, themeCancelSubscribe } from "../api/theme"
import {
  USER_THEME_TYPE_DESIGN,
  USER_THEME_TYPE_BUY,
  USER_THEME_TYPE_SUBSCRIBE,
  TOKEN,
  MEDIA_INTRO,
} from "../utils/constant"
import "./ThemeCard.css"
import { getCookie } from "../utils/helper"
import { DollarTwoTone, PushpinTwoTone } from "@ant-design/icons"

class ThemeCard extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      name: props.name,
      description: props.desc,
      avatar: props.publisherName,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.name,
      description: nextProps.desc,
      avatar: nextProps.publisherName,
    })
  }

  navigateDetailPage = () => {
    const { id } = this.props
    navigate(`/jobs/id/${id}`)
  }

  render() {
    let { name, desc, publisherName } = this.props
    if (desc) {
      desc = desc.slice(0, 30)
    }
    let handleCardClickEvent = this.navigateDetailPage

    return (
      <a className="banner-card" onClick={handleCardClickEvent}>
        <div className="mdnice-homepage-media-content">
          <h3 className="mdnice-homepage-media-wechat-name">{name}</h3>
          <p className="mdnice-homepage-media-wechat-description">
            {desc}
          </p>
          <a
            className="mdnice-homepage-media-wechat-link"
            target="_blank"
          >
            查看详情
          </a>
        </div>
        <div className="mdnice-homepage-media-avatar">
          <Avatar
            size={{ xs: 64, sm: 64, md: 80, lg: 80, xl: 80 }}
            style={{ background: "orange" }}
          >
            {publisherName && publisherName.slice(0, 1).toUpperCase()}
          </Avatar>
        </div>
      </a>
    )
  }
}

export default ThemeCard
